interface LStepTrackingPixelProps {
  pixelId?: string;
}

export const LStepTrackingPixel = ({
  pixelId = "62139/NK3ptk",
}: LStepTrackingPixelProps) => {
  return (
    <img
      src={`https://lstep.app/p/${pixelId}`}
      alt="" // Alt empty for tracking pixels
      width="1"
      height="1"
      style={{
        position: "absolute",
        width: "1px",
        height: "1px",
        padding: "0",
        margin: "-1px",
        overflow: "hidden",
        clip: "rect(0, 0, 0, 0)",
        whiteSpace: "nowrap",
        border: "0",
      }}
    />
  );
};
