import { Links, Meta } from "@remix-run/react";

interface MetaTagsProps {
  isProduction: boolean;
}

export const MetaTags = ({ isProduction }: MetaTagsProps) => {
  return (
    <>
      <meta charSet="utf-8" />
      {!isProduction && <meta name="robots" content="noindex, nofollow" />}
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <Meta />
      <Links />
    </>
  );
};
