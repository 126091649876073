import { useEffect } from "react";
import { useLocation } from "@remix-run/react";

interface GoogleTagManagerProps {
  isProduction: boolean;
  gtmId: string;
}

export const GoogleTagManager = ({
  isProduction,
  gtmId,
}: GoogleTagManagerProps) => {
  const location = useLocation();

  useEffect(() => {
    if (!isProduction) return;

    // Initialize dataLayer
    window.dataLayer = window.dataLayer || [];

    // GTM initialization
    const gtmScript = {
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    };
    window.dataLayer.push(gtmScript);

    // Add GTM script to document
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [isProduction, gtmId, location]);

  return null;
};

// type declaration for dataLayer
declare global {
  interface Window {
    dataLayer: any[];
  }
}
