interface EnvironmentErrorProps {
  message?: string;
  className?: string;
  showTechnicalDetails?: boolean;
}

export const EnvironmentError = ({
  message = "環境設定エラーが発生しました",
  className = "",
  showTechnicalDetails = false,
}: EnvironmentErrorProps) => {
  return (
    <div
      className={`min-h-screen flex items-center justify-center bg-gray-50 ${className}`}
    >
      <div className="max-w-md w-full space-y-6 p-8">
        <div>
          <h1 className="mt-6 text-center text-3xl font-bold text-gray-900">
            環境エラー
          </h1>
          <p className="mt-2 text-center text-sm text-gray-600">{message}</p>

          {showTechnicalDetails && (
            <div className="mt-4 p-4 bg-gray-100 rounded-md">
              <h2 className="text-sm font-medium text-gray-700 mb-2">
                技術的な詳細:
              </h2>
              <p className="text-xs text-gray-600">
                Current environment is null
              </p>
            </div>
          )}
        </div>

        <div className="space-y-4">
          {/* Reload button */}
          <button
            onClick={() => window.location.reload()}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            ページを再読み込み
          </button>

          {/* Back button */}
          <button
            onClick={() => window.history.back()}
            className="w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            前のページに戻る
          </button>
        </div>
      </div>
    </div>
  );
};
