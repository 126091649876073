import { withSentry } from "@sentry/remix";
import {
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from "@remix-run/react";
import type { LoaderFunctionArgs, LinksFunction } from "@remix-run/cloudflare";
import { json } from "@remix-run/cloudflare";

// Components
import { MetaTags } from "./components/root/MetaTags";
import { GoogleTagManager } from "~/components/root/GoogleTagManager";
import { LStepTrackingPixel } from "~/components/root/LStepTrackingPixel";
import { AuthorizationRequired } from "~/components/root/AuthorizationRequired";
import { EnvironmentError } from "~/components/root/EnvironmentError";
import { RootErrorBoundary } from "~/components/root/RootErrorBoundary";

// Utils & Context
import { getCurrentEnvironment } from "~/utils/GetCurrentEnvironment";
import { getStaticImageBucketEndpoint } from "~/utils/GetStaticImageBucketEndpoint";
import { EnvironmentProvider } from "~/context/EnvironmentContext";
import { Environments } from "~/types";

// Constants
import { GTM, STATIC_IMAGES, LINK_ATTRIBUTES, PATHS } from "~/constants";

// Styles
import stylesheet from "~/tailwind.css?url";

const isAuthorized = (
  request: Request,
  context: { cloudflare: { env: Env } }
) => {
  const header = request.headers.get("Authorization");
  if (!header) return false;
  const base64 = header.replace("Basic ", "");

  // Bufferの代わりにatob関数を使用
  const decoded = atob(base64);
  const [username, password] = decoded.split(":");

  return (
    username === context.cloudflare.env.BASIC_AUTH_ID &&
    password === context.cloudflare.env.BASIC_AUTH_PASS
  );
};

type LoaderData = {
  authorized: boolean;
  currentEnvironment: string | null;
};

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const environment = context.cloudflare.env.ENVIRONMENT;
  const requiresPartialAuthEnv = "production"; //リリースの際、ここにproductionという文字列を入れる
  const requiresAuth =
    (environment === requiresPartialAuthEnv &&
      url.pathname === PATHS.UPLOAD.JOB_LISTINGS) ||
    environment !== requiresPartialAuthEnv;

  const currentDomain = url.hostname;
  const currentEnvironment = getCurrentEnvironment(currentDomain);

  if (requiresAuth) {
    if (isAuthorized(request, context)) {
      return json<LoaderData>({
        authorized: true,
        currentEnvironment,
      });
    }
    return json<LoaderData>(
      {
        authorized: false,
        currentEnvironment: null,
      },
      {
        status: 401,
        headers: { "WWW-Authenticate": 'Basic realm="Secure Area"' },
      }
    );
  }
  return json<LoaderData>({
    authorized: true,
    currentEnvironment,
  });
};

export function Layout({ children }: { children: React.ReactNode }) {
  const loaderData = useLoaderData<typeof loader>() ?? null;
  const authorized = loaderData?.authorized ?? null; // デフォルト値を false に設定
  const isProduction =
    loaderData?.currentEnvironment === Environments.PRODUCTION;

  if (authorized !== null && !authorized) {
    return <AuthorizationRequired />;
  }

  if (loaderData?.currentEnvironment == null) {
    return (
      <EnvironmentError
        showTechnicalDetails={!isProduction} // Only show technical details in non-production
      />
    );
  }

  const staticImageBucketEndpoint = getStaticImageBucketEndpoint(isProduction);

  return (
    <html lang="ja">
      <head>
        <MetaTags isProduction={isProduction} />
        <GoogleTagManager isProduction={isProduction} gtmId={GTM.ID} />
      </head>
      <body>
        {isProduction && (
          <noscript>
            <iframe
              src={`https://www.googletagmanager.com/ns.html?id=${GTM.ID}`}
              height="0"
              width="0"
              style={{ display: "none", visibility: "hidden" }}
              title="Google Tag Manager"
            />
          </noscript>
        )}
        <EnvironmentProvider
          staticImageBucketEndpoint={staticImageBucketEndpoint}
        >
          {children}
        </EnvironmentProvider>
        <ScrollRestoration />
        <Scripts />
        <LStepTrackingPixel />
      </body>
    </html>
  );
}

function App() {
  return <Outlet />;
}

export default withSentry(App);

export function ErrorBoundary() {
  return <RootErrorBoundary />;
}

export const links: LinksFunction = () => [
  {
    rel: "apple-touch-icon",
    href: STATIC_IMAGES.APPLE_TOUCH_ICON,
  },
  {
    ...LINK_ATTRIBUTES.ANDROID_CHROME_192,
    href: STATIC_IMAGES.ANDROID_CHROME_192,
  },
  {
    ...LINK_ATTRIBUTES.ANDROID_CHROME_512,
    href: STATIC_IMAGES.ANDROID_CHROME_512,
  },
  { rel: "stylesheet", href: stylesheet },
];
